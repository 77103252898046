<template>
    <div class="card">
        <Toast />
        <form>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('FreeBoard.EditFreeBoard.Heading') }}</h5>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('FreeBoard.EditFreeBoard.Title') }}</label>
                            <InputText :class="`${error.title ? 'p-invalid' : ''}`" type="text"
                                :placeholder="$t('FreeBoard.EditFreeBoard.TitlePlace')" id="title2" v-model="title"
                                @keydown.space="preventLeadingSpace" maxlength="75"></InputText>
                            <div class="text-red">{{ error.title }}</div>
                        </div>
                    </div>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('FreeBoard.EditFreeBoard.Description') }}</label>
                            <div>
                                <Textarea :placeholder="$t('FreeBoard.EditFreeBoard.DescriptionPlace')"
                                    :autoResize="true" rows="5" cols="30" v-model="description"
                                    style="width: 100%; margin-left: 0px; margin-top: 14px"
                                    @keydown.space="preventLeadingSpace" maxlength="1500"
                                    :style="error?.description ? 'border: 1px solid red' : ''" />
                            </div>
                            <div class="text-red">{{ error.description }}</div>
                        </div>
                    </div>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('FreeBoard.EditFreeBoard.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('FreeBoard.EditFreeBoard.Select')" :class="`${error.country_id ? 'p-invalid' : ''}`"/>
                            <div class="text-red">{{ error.country_id }}</div>
                        </div>
                    </div>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('FreeBoard.EditFreeBoard.Category') }}</label>
                            <Dropdown v-model="category_id" :options="dropdownBoardCategoryValues" optionValue="id"
                                optionLabel="title" :placeholder="$t('FreeBoard.EditFreeBoard.Select')"
                                @click="getCategory" :class="`${error.category_id ? 'p-invalid' : ''}`"/>
                            <div class="text-red">{{ error.category_id }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                            <label for="subtitle2">
                                {{ $t('FreeBoard.EditFreeBoard.Upload') }}
                                <!-- <span class="img-info">(파일 형식: jpg,jpeg,png )(이미지 너비 924px and 고저 860px 및 크기 1MB )</span> -->
                            </label>
                            <div :class="`${error.file ? 'custom-select-invalid' : 'custom-select'}`">
                                <span v-if="!fileName">{{ $t('FreeBoard.EditFreeBoard.fileupload') }}</span>
                                <span v-else>{{ fileName }}</span>
                                <input type="file" class="select-file" v-on:change="onFileChange" />
                                <Button :label="$t('FreeBoard.EditFreeBoard.ChooseFile')" class="SelectBtn n-wrap" />
                            </div>
                            <div class="text-red">{{ error.file }}</div>
                            <div class="text-red" v-show="render1">유효하지 않은 파일 형식</div>
                            <div class="text-red" v-show="render2">최대 파일 크기는 5MB여야 합니다.</div>

                            <img :src="image_path" width="100px" height="100px" class="product-image"
                                v-if="image_path != ''" />
                        </div>
                    </div>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('FreeBoard.EditFreeBoard.Status') }}</label>
                            <br />
                            <InputSwitch v-model="expose" trueValue="1" />
                            <!-- <div class="text-red">{{ error.url }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-d-flex p-jc-end p-ai-center" style="float: left">
                <Button :label="$t('FreeBoard.AddFreeBoard.Save')" icon="pi pi-check" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="UpdateFreeBoard"></Button>
            </div>
            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button :label="$t('FreeBoard.AddFreeBoard.Back')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import axios from 'axios';
import ManagerService from '../../../service/API/SuperAdmin/ManagerService.js';

import FreeBoardService from '../../../service/API/SuperAdmin/FreeBoardService.js';
import validateUpdateFreeBoard from '../../../validations/FreeBoard/validateUpdateFreeBoard';
export default {
    name: 'FreeBoardAdd',
    // props: ['dog', 'image'],
    data() {
        return {
            render2: false,
            render1: false,
            dropdownstatusValues: [
                { name: 'Active', code: 'active' },
                { name: 'Inactive', code: 'inactive' },
            ],
            dropdownstatusValue: null,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,

            name: '',
            email: '',
            mobile: '',
            country_id: '',
            category_id: '',
            status: '',
            image: '',
            file: '',
            files: '',
            fileName: '',
            product_image: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            image_path: '',
            expose: '',
            // status:'',

            formData: new FormData(),
            error: {},
        };
    },
    created() {
        this.managerService = new ManagerService();
        this.freeBoardService = new FreeBoardService();
    },
    mounted() {
        this.freeBoardService.viewFreeBoard(this.$route.params.id).then((res) => {
            this.title = res.data.data[0].title;
            this.posted_date = res.data.data[0].posted_date;
            this.description = res.data.data[0].description;
            this.country_name = res.data.data[0].country_name;
            this.category_name = res.data.data[0].category_name;
            this.category_id = res.data.data[0].category_id;
            this.country_id = res.data.data[0].country_id;
            this.status = res.data.data[0].status;
            this.image = res.data.data[0].image;
            this.image_path = res.data.data[0].image_path;
            this.is_admin_post = res.data.data[0].is_admin_post;
            this.expose = res.data.data[0].expose;
            this.getCategory();
            // this.comment = res.data.data[0].comments[0].comment;
            // this.name = res.data.data[0].comments[0].name;
            // this.created_at = res.data.data[0].comments[0].created_at;

            console.log(res);
        });

        this.freeBoardService
            .getCountrydropdown()
            .then((data) => {
                this.dropdownCountryValues = data.data.data.country;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));
    },
    methods: {


        UpdateFreeBoard() {
            let vcheckData = {
                title: this.title,
                description: this.description,
                // type: this.dropdownValueType == null ? '' : 'something',
                category_id: this.category_id,
                country_id: this.country_id.toString(),
                // expose: this.expose,
                file: this.fileName == '' ? '' : 'something',
            };
            const { isInvalid, error } = validateUpdateFreeBoard(vcheckData);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                // console.log(this.file);
                this.formData.append('id', this.$route.params.id)
                this.formData.append('title', this.title);
                this.formData.append('description', this.description);
                this.formData.append('category_id', this.category_id);
                this.formData.append('country_id', this.country_id);
                this.formData.append('expose', this.expose == '1' ? '1' : '0');
                // status: this.status == 'active' ? 'active' : 'inactive'
                // this.formData.append('type', this.dropdownValueType?.name);
                // this.formData.append('status', this.dropdownValue.name===undefined ? this.dropdownValue :this.dropdownValue.name);
                console.log(this.formData);
                return axios.put('/asnhist-dev/api/v1/admin/free-board/edit', this.formData).then(() => {
                    this.$router.push({ name: 'FreeBoardManagementlist' });
                    setTimeout(() => {
                        this.$toast.add({ severity: 'info', summary: '확인되었습니다.', detail: '커뮤니티 게시물이 성공적으로 업데이트되었습니다.', life: 3000 });
                    }, 1000);
                });
            }
        },
        // onFileChange(e) {
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) return;
        //     this.file = files[0];
        //     var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        //     if (!allowedExtensions.exec(this.file.name)) {
        //         this.render1 = true;
        //         return false;
        //     } else {
        //         this.render1 = false;
        //         this.fileName = this.file.name;
        //         this.formData.append('image', files[0]);
        //     }
        //     this.fileExtension = this.fileName.replace(/^.*\./, '');
        //     console.log(this.fileName);
        // },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            // if (!files.length) return;
            this.file = files[0];
            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!allowedExtensions.exec(this.file.name)) {
                this.render1 = true;
                this.render2 = false;
            } else {
                let reader = new FileReader
                reader.readAsDataURL(this.file)
                reader.onload = (e) => {
                    let url = e.target.result
                    this.image_url = url
                }
                this.render1 = false;
                if (this.file.size > 5242880) {
                    // alert('파일이 너무 큽니다  ');
                    this.render2 = true;
                } else {
                    this.render2 = false;
                    this.fileName = this.file.name;
                    this.formData.append('image', files[0]);
                    // this.upload(e)
                }
            }
            this.fileExtension = this.fileName.replace(/^.*\./, '');
            console.log(this.fileName);
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        getCategory() {

            this.freeBoardService.getFreeCategorydropdown(this.country_id, this.category_type).then((res) => {

                this.dropdownBoardCategoryValues = res.data.data.board_category;
                console.log(res);
            });
        },
    },
};
</script>

<style scoped>
.img-info {
    font-size: 11px;
    font-weight: 400;
    color: rgb(48, 48, 48);
}

.custom-select {
    position: relative;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.custom-select-invalid {
    position: relative;
    border: 1px solid red;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.select-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    z-index: 5;
    opacity: 0;
}

.SelectBtn {
    max-width: 100px;
}

.custom-select span {
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
    float: left;
}
</style>
